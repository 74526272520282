import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { InlineLink, PaywallFeatures } from '@/components';
import { LandingLayout } from '@/layouts';

import { PaywallPlans } from '../../components/PaywallPlans/PaywallPlans';
import { usePremium } from '../../services/pro';
import styles from './LandingPlan.module.scss';

export function LandingPlan(): ReactNode {
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const onSkip = (): void => {
    Cookies.set('landing_skip', 'true', { expires: 1 });
  };

  if (premiumLoading) {
    return null;
  } else if (isPremium === true) {
    return <Navigate to="/" replace />;
  }

  return (
    <LandingLayout
      leftChild={
        <div className={styles.container}>
          <PaywallFeatures />
        </div>
      }
      rightChild={
        <div className={styles['right-container']}>
          <div className={styles.skip}>
            <InlineLink href="/" onClick={onSkip}>
              Skip
            </InlineLink>
          </div>
          <PaywallPlans />
        </div>
      }
    />
  );
}
