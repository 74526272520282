import cn from 'clsx';
import { type ReactElement, useEffect, useState } from 'react';

import DeleteIcon from '@/assets/delete-modal-icon.svg?react';
import {
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogTitle,
  Button,
} from '@/components';
import { appConfig } from '@/providers/config';

import styles from './DeleteContent.module.scss';

export function DeleteContent({
  onClick,
  loading,
}: {
  onClick?: () => void;
  loading: boolean;
}): ReactElement {
  const [randomCode, setRandomCode] = useState<number>();
  const [inputCode, setInputCode] = useState<number>();

  useEffect(() => {
    const generateRandomCode = (): number => {
      return Math.floor(100000 + Math.random() * 900000);
    };
    setRandomCode(generateRandomCode());
  }, []);

  return (
    <>
      <AlertDialogTitle>
        {' '}
        <DeleteIcon />
        Delete Account
      </AlertDialogTitle>

      <div className={styles.content}>
        <p className={styles['sub-title']}>
          Are you sure you want to delete your profile?
        </p>
        <div className={styles['modal-content']}>
          <ul className={styles['modal-content__list']}>
            <li>
              Deleting your account is permanent and{' '}
              <strong>cannot be undone.</strong>
            </li>
            <li>
              Deletion will prevent you from accessing {appConfig.name}{' '}
              services, including GPT-4o, and Google Gemini.
            </li>
            <li>
              Your data will be deleted within 30 days, except we may retain a
              limited set of data for longer where required or permitted by law.
            </li>
            <li>You&apos;ll be logged out on all devices.</li>
          </ul>
        </div>
        <div className={styles['modal-input']}>
          <p className={styles['modal-input__text']}>
            Enter the number {randomCode} to continue:
          </p>
          <input
            type="text"
            name="confirmationCode"
            className={styles['modal-input__input']}
            placeholder="Enter code"
            onChange={(e) => {
              setInputCode(Number(e.target.value));
            }}
          />
        </div>
      </div>

      <AlertDialogActions>
        <AlertDialogCancel asChild>
          <Button
            size="large"
            type="button"
            variant="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </AlertDialogCancel>

        <Button
          className={cn({
            [styles['button-disabled']]: inputCode !== randomCode,
          })}
          size="large"
          type="submit"
          variant="destructive"
          onClick={onClick}
          disabled={inputCode !== randomCode || loading}
        >
          Delete
        </Button>
      </AlertDialogActions>
    </>
  );
}
