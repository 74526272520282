import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement, useState } from 'react';

import { Button, Icon, InlineLink, Logo } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import usePathType from '@/hooks/path-type';
import { LandingLayout } from '@/layouts';

import { LandingCommentsAnimation } from '../../components/LandingComments/LandingComments';
import { LandingGpt4oSignUpForm } from '../../components/LandingGpt4oSignUpForm/LandingGpt4oSignUpForm';
import { RegisterLegalText } from '../../components/RegisterLegalText/RegisterLegalText';
import { AppleLogin } from '../../components/SocialLogin/AppleLogin/AppleLogin';
import { GoogleLogin } from '../../components/SocialLogin/GoogleLogin/GoogleLogin';
import styles from './LandingGpt4oSignUp.module.scss';

interface StepType {
  content: ReactElement;
}

export function LandingGpt4oSignUp(): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const path = usePathType();
  const handleLoginWithEmail = (): void => {
    setStep(steps[1]);
  };

  const steps: StepType[] = [
    {
      content: (
        <div className={styles['login-methods']}>
          <p className={styles['login-description']}>
            Continue with one of these:
          </p>
          <div className={styles['login-buttons']}>
            <GoogleLogin
              className={styles['button-google']}
              iconClassName={styles['button-google-icon']}
              variant={'secondary'}
              source={'register'}
            />
            <AppleLogin
              className={styles['button-apple']}
              iconClassName={styles['button-apple-icon']}
              variant={'secondary'}
              source={'register'}
            />
            <Button
              className={styles['button-email']}
              size="large"
              variant={'secondary'}
              type="button"
              onClick={handleLoginWithEmail}
            >
              <Icon className={styles['button-email-icon']} icon={faEnvelope} />
              Continue with E-mail
            </Button>
          </div>
          <RegisterLegalText />
        </div>
      ),
    },
    {
      content: <LandingGpt4oSignUpForm />,
    },
  ];
  const [step, setStep] = useState<StepType>(steps[0]);

  const handleLoginButtonClick = (): void => {
    logBigQueryEvent('btn_login', {
      eventValue: {
        source: 'register_page_content',
      },
    });

    logEvent('btn_login', {
      source: 'register_page_content',
    });
  };

  return (
    <LandingLayout
      leftChild={<LandingCommentsAnimation />}
      rightChild={
        <div className={styles.container}>
          <Logo className={styles.logo} size={'medium'} />
          <div className={styles.content}>
            <h1 className={styles.title}>Join millions of Happy Users</h1>
            <p>
              Rest assured, your data remains secure, and you will not be
              subjected to any spam!
            </p>
            {step.content}

            <div className={styles['login-info']}>
              <span>Do you have an account?</span>{' '}
              <InlineLink
                href={`/${path}/login`}
                onClick={handleLoginButtonClick}
              >
                Log in
              </InlineLink>
            </div>
          </div>
        </div>
      }
    />
  );
}
